import { useEffect, useState } from 'react';
import api from 'api';

import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Heading from 'components/partials/heading/heading';
import Loader from 'components/partials/loader/loader';
import { CustomSelect as Select } from 'components/partials/custom-select/custom-select';

import GoalItem from 'containers/user/goals/goal-item';
import { generateYearOptions } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-matrix/goals-matrix-filters/goals-matrix-filters';

import { useCurrentUser } from 'state/current-user/current-user.thunk';
import { getCurrentYear } from 'containers/admin/clients/client/client-details';
import EmptySpaceContent from 'components/partials/empty-space-content/empty-space-content';

import Icons from 'assets/icons';

const MyGoals = () => {
  const [loading, setLoading] = useState(true);
  const [goals, setGoals] = useState([]);
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());

  const years = generateYearOptions();

  const currentUser = useCurrentUser();

  useEffect(() => {
    const getGoals = async () => {
      setLoading(true);
      try {
        const res = await api.getAllGoals({
          userId: currentUser.data.oktaUserId,
          clientId: currentUser.data.client.id,
          year: selectedYear,
        });

        if (res?.data?.content) {
          setGoals(res.data.content);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    getGoals();
  }, [selectedYear]);

  return (
    <ResponsiveWrapper customClass="mt-8 xs:max-w-[900px]">
      <Loader loading={loading} fullScreen={false} customClass="transition" />
      <div className="flex items-center mb-6 gap-4">
        <Heading variant="h2">My Goals</Heading>
        <Select
          options={years}
          value={years.find((year) => year.value === selectedYear)}
          onChange={(option) => setSelectedYear(option.value)}
          customSelectWrapperClass="!w-28 mr-4"
          extraBasicStyles={{ padding: 0, height: '2' }}
        />
      </div>

      {!goals.length && !loading && (
        <EmptySpaceContent
          image={<Icons.EmptyImageAll customClass="w-52 h-52" />}
          text="No goals have been defined for the selected period yet."
        />
      )}
      <div className="flex flex-wrap gap-4 mt-4">
        {goals.map((goal) => (
          <GoalItem key={goal.id} goal={goal} />
        ))}
      </div>
    </ResponsiveWrapper>
  );
};

export default MyGoals;
