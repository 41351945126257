import classNames from 'classnames';
import {
  getValueType,
  renderValue,
} from 'containers/admin/clients/client/tabs-components/goals-tab/goals-matrix/goals-matrix-cell/goals-matrix-cell';
import { getGoalIcon } from 'containers/user/home/components/widgets/goals-widget/goal-carusel';

const GoalItem = ({ goal }) => {
  const valueType = getValueType(goal);
  const { icon, color } = getGoalIcon(goal?.relativeToLastYear);

  return (
    <div className="bg-gray-00 rounded-md w-72 h-40 flex flex-col justify-center border border-navy-01">
      <div className="flex flex-col justify-center items-center">
        <p className="font-medium mb-2">
          {goal.relativeToLastYear} {goal.subType || goal.details[0].focusArea}{' '}
          to
        </p>
        <p
          className={classNames('text-3xl font-bold flex items-center', color)}
        >
          {renderValue({
            value: goal.value,
            valueType,
            goalType: goal.type,
          })}
          {icon && <span className="ml-2">{icon}</span>}
        </p>
      </div>
    </div>
  );
};

export default GoalItem;
