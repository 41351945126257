import React from 'react';
import { Link } from 'react-router-dom';

import {
  SkeletonLoader,
  NoGoalsDefined,
  NoToplineGoals,
} from 'containers/user/home/components/widgets/goals-widget/goals-widget-states';
import GoalsCarusel from 'containers/user/home/components/widgets/goals-widget/goal-carusel';
import CustomButton from 'components/partials/custom-button/custom-button';
import ErrorWidget from 'containers/user/home/components/widgets/error-widget';

import Icons from 'assets/icons';

export const goalsConfig = {
  colSpan: 1,
  rowSpan: {
    default: 9,
  },
};

const renderContent = (goals, error) => {
  if (error) return <ErrorWidget />;
  if (!goals.length) return <NoToplineGoals />;

  return <GoalsCarusel goals={goals} />;
};

const GoalsWidget = ({ loading, error, goals = [], hasClientGoals = true }) => {
  if (loading) return <SkeletonLoader />;
  if (!hasClientGoals) return <NoGoalsDefined />;

  return (
    <div className="h-full bg-white border border-gray-05 rounded-lg flex flex-col p-5 pt-4">
      <div className="flex items-center gap-2 mb-4">
        <Icons.Target className="h-5 w-5 text-indigo-04" />
        <h3 className="text-2xl font-bold">Your Topline Goals</h3>
      </div>

      {renderContent(goals, error)}

      {!error && (
        <div className="pt-4">
          <Link to="/goals">
            <CustomButton
              variant="primary"
              size="sm"
              customClass="self-start"
              onClick={() => console.log('See all goals clicked')}
              iconAfter={<Icons.DoubleArrow className="ml-2 w-2 h-2" />}
            >
              See All Goals
            </CustomButton>
          </Link>
        </div>
      )}
    </div>
  );
};

export default GoalsWidget;
