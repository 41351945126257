import React from 'react';
import api from 'api';

import withWidgetData from 'containers/user/home/components/widgets/HOC/with-widget-data-hoc';
import Announcement, {
  announcementConfig,
} from 'containers/user/home/components/widgets/announcement';
import ReleaseNotes, {
  getReleaseNotesConfig,
} from 'containers/user/home/components/widgets/release-notes';

import {
  ANNOUNCEMENT,
  RELEASE_NOTE,
  GOAL,
  VISUAL,
} from 'containers/user/home/components/widgets/widgets.constants';

import GoalsWidget, {
  goalsConfig,
} from 'containers/user/home/components/widgets/goals-widget/goals-widget';

import PowerBIWidget, {
  powerBiWidgetConfig,
} from 'containers/user/home/components/widgets/powerbi';

const AnnouncementWithData = withWidgetData(Announcement);
const ReleaseNotesWithData = withWidgetData(ReleaseNotes);
const GoalsWidgetWithData = withWidgetData(GoalsWidget);

const widgetMap = {
  [ANNOUNCEMENT]: {
    component: AnnouncementWithData,
    config: announcementConfig,
  },
  [RELEASE_NOTE]: {
    component: ReleaseNotesWithData,
  },
  [GOAL]: {
    component: GoalsWidgetWithData,
    config: goalsConfig,
  },
  [VISUAL]: {
    component: PowerBIWidget,
    config: powerBiWidgetConfig,
  },
};

const buildGeneralWidget = (widgetType, item, currentUser, hasImage) => {
  const { component: WidgetComponent, config } = widgetMap[widgetType] || {};

  if (!WidgetComponent) {
    return null;
  }

  return {
    id: item.id,
    widgetComponent: (
      <WidgetComponent
        userId={currentUser.data.oktaUserId}
        widgetId={item.id}
        hasImage={hasImage}
      />
    ),
    ...config,
  };
};

const createWidgetObject = async (item, currentUser) => {
  const widgetType = item.widget.type;

  if (widgetType === RELEASE_NOTE) {
    try {
      const { data } = await api.getWidget({
        userId: currentUser.data.oktaUserId,
        id: item.id,
      });

      const hasImage = !!data.widget.content.data.attributes.image;
      const dynamicConfig = getReleaseNotesConfig(hasImage);

      const generalWidget = buildGeneralWidget(
        widgetType,
        item,
        currentUser,
        hasImage
      );

      return generalWidget ? { ...generalWidget, ...dynamicConfig } : null;
    } catch (error) {
      console.error('Error fetching release note widget data:', error);

      return null;
    }
  }

  return buildGeneralWidget(widgetType, item, currentUser);
};

const getWidgetsSet = async (responseData, currentUser) => {
  const widgets = await Promise.all(
    responseData.map((item) => createWidgetObject(item, currentUser))
  );

  return widgets.filter(Boolean);
};

export default getWidgetsSet;
