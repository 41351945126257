import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Icons from 'assets/icons';

import {
  renderValue,
  getValueType,
} from 'containers/admin/clients/client/tabs-components/goals-tab/goals-matrix/goals-matrix-cell/goals-matrix-cell';

export const getGoalIcon = (type) => {
  switch (type) {
    case 'Increase':
      return {
        icon: <Icons.ArrowUp className="h-7 w-7 text-success-06" />,
        color: 'text-success-06',
      };
    case 'Decrease':
      return {
        icon: <Icons.ArrowDown className="h-7 w-7 text-red-04" />,
        color: 'text-red-04',
      };
    case 'Maintain':
      return {
        icon: <Icons.Maintain className="h-7 w-7 text-gray-04" />,
        color: 'text-gray-04',
      };
    default:
      return { icon: null, color: '' };
  }
};

const GoalsCarusel = ({ goals }) => {
  const [currentGoalIndex, setCurrentGoalIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const currentGoal = goals[currentGoalIndex];
  const valueType = getValueType(currentGoal);

  const { icon, color } = getGoalIcon(currentGoal?.relativeToLastYear);

  const handleNext = () => {
    setIsPaused(true);
    setCurrentGoalIndex((prevIndex) => (prevIndex + 1) % goals.length);
  };

  const handlePrev = () => {
    setIsPaused(true);
    setCurrentGoalIndex(
      (prevIndex) => (prevIndex - 1 + goals.length) % goals.length
    );
  };

  const isSingleGoal = goals.length === 1;

  useEffect(() => {
    if (isSingleGoal || isPaused) return;

    const interval = setInterval(() => {
      setCurrentGoalIndex((prevIndex) => (prevIndex + 1) % goals.length);
    }, 2800);

    return () => clearInterval(interval);
  }, [goals.length, isPaused, isSingleGoal]);

  return (
    <div className="flex-1 bg-gray-00 p-4 rounded-lg flex items-center justify-between">
      {!isSingleGoal && (
        <button
          onClick={handlePrev}
          className="text-blue-04 hover:text-blue-06 transition"
          aria-label="Previous goal"
        >
          <Icons.Arrow className="h-5 w-5 rotate-180" />
        </button>
      )}

      <div className="flex-1 flex flex-col justify-center items-center">
        <p className="font-medium mb-2">
          {currentGoal.relativeToLastYear}{' '}
          {currentGoal.subType || currentGoal.details[0].focusArea} to
        </p>
        <p
          className={classNames('text-3xl font-bold flex items-center', color)}
        >
          {renderValue({
            value: currentGoal.value,
            valueType,
            goalType: currentGoal.type,
          })}
          {icon && <span className="ml-2">{icon}</span>}
        </p>
      </div>

      {!isSingleGoal && (
        <button
          onClick={handleNext}
          className="text-blue-04 hover:text-blue-06 transition"
          aria-label="Next goal"
        >
          <Icons.Arrow className="h-5 w-5" />
        </button>
      )}
    </div>
  );
};

export default GoalsCarusel;
